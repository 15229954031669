<template>
  <div class="container">
    <div class="invitaion_top" ref="invitaionTop">
      <div class="invitaion_router" @click="goBack">
        <img src="../assets/played_left.png" class="invitaion_back" alt="">
        <span class="invitaion_title">新用户专享活动</span>
      </div>
    </div>
    <div class="invitaion_center">
      <span class="invitaion_center_txt">输入邀请码</span>
      <input type="text" class="invitaion_center_code" :readonly='readonly' v-model="value">
      <div class="invitaion_line"></div>
      <div class="invitaion_center_des">
        <span class="invitaion_center_des_title">活动规则 :</span>
        <span class="invitaion_center_des_txt">*输入好友分享邀请码绑定手机并充值一笔, 可获得5个糖豆 (价值5元)</span>
        <span class="invitaion_center_des_txt">*每人仅可填写一次邀请码</span>
        <div class="invitaion_share">
          <span 
            class="invitaion_center_share" @click="onClick" v-if="currentState == 1"
          >提交</span>
          <span 
            class="invitaion_center_share" style="background:#606060" v-if="currentState == 4"
          >已领取</span>
          <span 
            class="invitaion_center_share" 
            v-if="currentState == 2 || currentState == 3" @click="onLClick"
          >领取糖豆</span>
        </div>
      </div>
    </div>
    <!-- 绑定手机号 -->
    <van-action-sheet
      v-model="admin.bind"
      title="绑定手机号"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s5"
          right-icon="closed-eye"
          @click-right-icon="clickIcon('s5')"
          v-model="form.password"
          placeholder="6-12位英文或数字"
        />
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c1')"
            ref="c1"
          >
            获取验证码
          </div>
        </div>
        <div class="my_save_btn" @click="clickHandle('bindphone')">保存</div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import {
  askReceiveInvitation,
  askUploadInvitationView,
  askDoGetInvitaionCoin,
  askSavePhone,
  askGetCode
} from "@/api";
import { mapGetters, mapState, mapActions } from "vuex";
import { ksort } from "@/utils/ksort";
import { Dialog, Toast } from 'vant';
import { Encrypt, Decrypt } from "@/utils/index";
export default {
  data() {
    return {
      value: null,
      admin: {
        bind: false,
      },
      ps: {
        s5: "password",
      },
      form: {
        password: "", // 旧密码
        phone: "", // 手机号
        code: "", // 验证码
      },
      readonly: false,
      currentState: null
    }
  },
  mounted() {
    if (judgeBigScreen()) {
      this.$refs.invitaionTop.className = "invitaion_top invitaionTopAndroid";
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getData()
    })
  },
  methods: {
    getData() {
      var params = {
        token: this.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, 'qsdhajasfas123sq')
      askUploadInvitationView(params).then(res => {
        if(res.code == 1) {
          this.currentState = res.data.state
          if(res.data.code) {
            this.value = res.data.code
            this.readonly = true
          }
        }
      })
    },
    clickIcon(e) {
      this.ps[e] = "text";
    },
    goBack() {
      store.commit("incrementOut");
      // store.commit("tabbarIn");
      this.$router.go(-1);
    },
    onClick() {
      if(this.readonly) { 
        Toast('已有邀请码');
      } else {
        var params = {
          token: this.CYBoxUser.loginToken,
          code: this.value,
          time: Math.floor(Date.now() / 1000)
        }
        params.sign = ksort(params, 'qsdhajasfas123sq')
        askReceiveInvitation(params).then(res => {
          if(res.code == 1) {
            Toast('领取成功!');
            store.commit("incrementOut");
            // store.commit("tabbarIn");
            this.$router.go(-1);
          } else {
            Toast(res.message);
          }
        })
      }
    },
    onLClick() {
      var params = {
        token: this.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000),
      }
      params.sign = ksort(params, 'qsdhajasfas123sq')
      askDoGetInvitaionCoin(params).then(res => {
        this.getData()
        if(res.code == 1) {
          Toast(res.message);
        } else {
          Toast(res.message);
          this.admin.bind = true
        }
      })
    },
    clickHandle(handle, transmit) {
      switch (handle) {
        case "bindphone":
          var params = {
            appid: this.appid,
            username: this.CYBoxUser.loginName,
            password: this.form.password,
            code: this.form.code,
            newPhoneNum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askSavePhone(params).then((res) => {
            if (res.state === "1") {
              var temp = res.message;
              temp.password = Encrypt(res.message.password);
              store.commit("listUpdate", temp);
              store.commit("userUpdate", temp);
              this.admin.bind = false;
              Toast.success("绑定成功");
              this.form = this.$options.data().form;
              this.getData()
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "getcode":
          var params = {
            appid: this.appid,
            phonenum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askGetCode(params).then((res) => {
            if (res.state === "1") {
              var i = Number(res.message);
              Toast("验证码已发送, 请查收");
              var timer = setInterval(() => {
                if (i == 0) {
                  this.$refs[transmit].style.cssText +=
                    "background:linear-gradient(90deg, #ff5659 35%, #ff0103 100%);pointer-events: auto;";
                  this.$refs[transmit].innerHTML = "获取验证码";
                  clearInterval(timer);
                } else {
                  this.$refs[transmit].style.cssText +=
                    "background:#666;pointer-events: none;";
                  this.$refs[transmit].innerHTML = i;
                }
                i--;
              }, 1000);
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapGetters([
      "appid",
      "appkey",
      "bundle",
      "CYBoxUser",
      "CYBoxIDFA",
    ]),
  },
}
</script>
<style lang="stylus" scoped>
@import '../components/common.styl';
.invitaion_top
  background: url('../assets/top.png') center;
  background-size: 100% 100%;
  height: calc(242px + constant(safe-area-inset-bottom));
  height: calc(242px + env(safe-area-inset-bottom));
  position: relative;
  display flex
  justify-content center
.invitaion_router
  height 42px
  display flex
  align-items center
  position relative
  width: 100%;
  justify-content: center;
.invitaion_back
  position absolute
  height 20px
  width 12px
  top calc(12px + constant(safe-area-inset-bottom));
  top calc(12px + env(safe-area-inset-bottom));
  left 16px
.invitaion_title
  position absolute
  top calc(10px + constant(safe-area-inset-bottom));
  top calc(10px + env(safe-area-inset-bottom));
  color #fff
  font-size 20px
.invitaion_center
  width 86%
  height 420px
  background #fff
  display flex
  flex-direction column
  border-radius 20px
  margin: 0 auto;
  text-align: center;
  position absolute
  left 7%
  top 12%
.invitaion_center_txt
  font-size 18px
  margin-top 44px
.invitaion_center_code
  height 48px
  line-height 48px
  font-size 26px
  color #ee3737
  margin 24px auto 14px
  text-align: center;
  background #f0f0f0
  width 206px
  border-radius: 10px;
  font-weight 600
.invitaion_copy
  height 30px
  line-height 30px
  width 126px
  border-radius 15px 
  border 1px solid #ee3737
  color #ee3737
  text-align center
  margin: 0 auto;
.invitaion_line
  width 100%
  border-top 1px dashed #cccccc
  margin-top: 38px;
.invitaion_center_des
  display flex
  flex-direction column
  text-align: left;
  padding-top: 20px;
.invitaion_center_des_title
  padding-left: 20px;
  font-size 16px
  font-weight 600
  margin-bottom 10px
.invitaion_center_des_txt
  padding-left: 20px;
  font-size 14px
  color #606060
  margin-bottom 2px
  padding-right: 10px;
.invitaion_center_share
  width 132px
  height 36px
  border-radius 18px
  color #fff
  line-height 36px
  text-align center
  background linear-gradient(90deg, $themeMin 45%, $themeMax 100%)
  margin 40px auto
.invitaion_share
  display flex
</style>